new WOW().init();

$(document).ready(function() {

	
	/*Title animate*/
	if($('.animate-title').length){
		$('.animate-title').each(function(thisHtml){
			var thisText = $(this).html();
			var thisHtml = '<span class="mask"><span>' + thisText.split(/ (?=[^>]*(?:<|$))/).join('</span></span> <span class="mask"><span>') + '</span></span>';
			$(this).html(thisHtml);
			var delay = -1, prev_offset = 0;
			$(this).find('.mask').each(function(){
				var offset = $(this).position().top;
				if (prev_offset != offset){
					prev_offset = offset;
					delay++
				}
				$(this).addClass('delay' + delay);
			});

			$(this).find('span').each(function(){
				if($(this).find('br').length){
					$(this).after('<br />').find('br').remove();
				}
			});
		});
	}

	setTimeout(function(){
		$('body').addClass('page-load');
		$('.animate-title').removeClass('color-transparent')
	}, 1000);  
  
	

	var slider = new Swiper ('.slider', {
		slidesPerView: 1,
		spaceBetween: 40,
		loop: true,
		navigation: {
			nextEl: '.slider .btns__next',
			prevEl: '.slider .btns__prev',
		}
	});



	var mySwiper = new Swiper ('.main-reviews .swiper-container', {
		slidesPerView: 1,
		spaceBetween: 40,
		loop: true,
		navigation: {
			nextEl: '.main-reviews .btns__next',
			prevEl: '.main-reviews .btns__prev',
		}, 
		breakpoints: {
			992: {
				slidesPerView: 2
			}
		}
	});


	var mySwiper = new Swiper ('.main-solutions .swiper-container', {
		slidesPerView: 1,
		spaceBetween: 40,
		loop: true,
		navigation: {
			nextEl: '.main-solutions .btns__next',
			prevEl: '.main-solutions .btns__prev',
		},
		breakpoints: {
			992: {
				slidesPerView: 2,
				spaceBetween: 30
			}
		}
	});


	var mySwiper = new Swiper ('.partners-solutions .swiper-container', {
		slidesPerView: 1,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.partners-solutions .btns__next',
			prevEl: '.partners-solutions .btns__prev',
		},
		breakpoints: {
			992: {
				slidesPerView: 3,
				spaceBetween: 40
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 40
			},
		}
	});

	var mySwiper = new Swiper ('.partners-slider .swiper-container', {
		slidesPerView: 1,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.partners-slider .btns__next',
			prevEl: '.partners-slider .btns__prev',
		},    
		breakpoints: {
			768: {
				slidesPerView: 3,
			},
			992: {
				slidesPerView: 4
			},
			1200: {
				slidesPerView: 5
			}
		},
	});

	$('.open-modal').click(function(){
		$('.overlay').fadeIn();
		$('#'+$(this).data('id')).fadeIn().animate({'top':  $('.overlay').height()/2-$('#'+$(this).data('id')).height()/2},100);
		return false;
	});

	$('.close-modal, .overlay').click(function(){
		$('.overlay').fadeOut();
		$('.modal').animate({'top':  '100%'},100).fadeOut();
		$('.fixed-modal').animate({'right': -$('.fixed-modal').width()},100);
		return false;
	});

	$('.open-fixed-modal').click(function(){
		$('.overlay').fadeIn();
		$('#'+$(this).data('id')).show().animate({'right': 0},100);
		return false;
	});

	$('.cookies__close').click(function(){
		$(this).parent().hide();
	});

	$('.footer__title.parent').click(function(){
		$(this).toggleClass('active');
		$(this).parent().find('.footer-menu').slideToggle();
	});

  
	$('.m-menu').click(function(){
		$('.header').slideToggle();
	});

	$('.nav__item-arrow').click(function(){
		$(this).toggleClass('active');
		$(this).parent().parent().find('.nav-cat-wrap').slideToggle();
	});

	// выбор видео
	$('.video-group__list-item').on('click', function(){
		$('.video-group__list-item').removeClass('active')
		$(this).addClass('active');
		$('.video-group__active-text').text($(this).data('text'));
		$('.video-group__active').attr('href', $(this).data('url'));
	});

	// вопрос ответ на стр продукт
	$('.collapse__btn').click(function(){
		$(this).toggleClass('active');
		$(this).parent().find('.collapse__text').slideToggle();
	});

	// стр. поддержка	
	$('.support-services').on('click', '.support-services__name-item:not(.active)', function() {
	    $(this)
	      .addClass('active').siblings().removeClass('active')
	      .closest('.support-services').find('.support-services__text').removeClass('active').eq($(this).index()).addClass('active');
	});

	// запуск индикаторов
	if($('.indicators-wrap').length) {
		if( $('html').scrollTop() > ($('.indicators-wrap').offset().top-300)) {
			circleDownload();    
		}
	}
	
	// пресс-центр - больше материалов
	$('.press-material__more').click(function(){
		$(this).hide();
		$(this).parent().find('.press-material__item').fadeIn();
		return false;
	});

	// стр. решения партнеров - показать все лого
	$('.show-more-partners').click(function(){
		$('.show-more-partners, .big-logos').hide();
		$('.hide-more-partners, .partners-group').show();
		return false;
	});

	// стр. решения партнеров - скрыть лого
	$('.hide-more-partners').click(function(){
		$('.hide-more-partners, .partners-group').hide();
		$('.big-logos, .show-more-partners').show();
		$('html, body').animate({ scrollTop: ($('.big-logos').offset().top - 100) }, 500);
		return false;
	});

	// стр. курсы - фильтр
	$('.courses-filter__item').click(function(){
		$('.courses-filter__item').removeClass('active');
		$(this).addClass('active');
		$('.course').hide();
		if($(this).data('group')=='all') {
			$('.course').show();
		} else {
			$('.'+$(this).data('group')).show();
		}		
		return false;
	});

	// табы
	$('.tabs__caption').on('click', '.tabs__caption-item:not(.active)', function() {
	    $(this)
	      .addClass('active').siblings().removeClass('active')
	      .closest('.tabs').find('.tabs__content').removeClass('active').eq($(this).index()).addClass('active');
	});

	$('.tabs__content-title').click(function(){
		$(this).toggleClass('active');
		$(this).parent().find('.tabs__content-text').slideToggle();
	});

	$('.mask-phone').mask('+7 (999) 999-9999');
	$('.mask-sert').mask('999-99-999999');

  
	$('.vacancy form').on('submit', function(){
		$(this).validate({
			rules: {
				name: {
					required: true,
					minlength: 2
				},
				mail: {
					required: true,
					email: true
				},
                phone: {
                    required: true,
					minlength: 16
                }
			}
		});
		return false;

		//валидация   успех

		
	});
	
});



  
$( window ).scroll(function() {
	if( $(this).scrollTop() > $('.header').height()) {
    	$('.fixed-nav').addClass('fixed');
  	} else{    
    	$('.fixed-nav').removeClass('fixed');
  	}
	if($('.indicators-wrap').length) {
		if( $(this).scrollTop() > ($('.indicators-wrap').offset().top-300)) {
			circleDownload();    
		}
	}
	if($('.support-indicators').length) {
		if( $(this).scrollTop() > ($('.support-indicators').offset().top-300)) {
			circleDownload2();    
		}
	}
});


function circleDownload() {
  if($('.indicators-wrap').hasClass('animate')) {

    $("#circle1").circliful({
      animation: 1,
      animationStep: 5,
      foregroundBorderWidth: 15,
      backgroundBorderWidth: 15,
      percent: 78,
    })

    $("#circle2").circliful({
      animation: 1,
      animationStep: 5,
      foregroundBorderWidth: 15,
      backgroundBorderWidth: 15,
      percent: 82
    })

    $("#circle3").circliful({
      animation: 1,
      animationStep: 5,
      foregroundBorderWidth: 15,
      backgroundBorderWidth: 15,
      percent: 32
    });
    $('.indicators-wrap').removeClass('animate');
  }
}

function circleDownload2() {
  if($('.support-indicators').hasClass('animate')) {

    $("#circle4").circliful({
      animation: 1,
      animationStep: 5,
      foregroundBorderWidth: 15,
      backgroundBorderWidth: 15,
	  strokeLinecap: "round",
      percent: 95.7,
    })

    $("#circle5").circliful({
      animationStep: 5,
      foregroundBorderWidth: 15,
      backgroundBorderWidth: 15,
	  showPercent: 0,
	  percent: 45,
    })

    $("#circle6").circliful({
      animation: 1,
      animationStep: 5,
      foregroundBorderWidth: 15,
      backgroundBorderWidth: 15,
      percent: 84.5
    });
    $('.support-indicators').removeClass('animate');
  }
}